<template>
  <div class="assessBox">
    <div class="app-container" slot="header">
      <div style="background-color:#f6f7fb;width:100%;height:20px" v-if="iscompoent"></div>
      <div class="titlebox" :style="iscompoent?'margin:0 20px 20px':'margin:20px'">
        <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
        <!-- <el-button
          size="medium"
          style="float: right;color: #fff;"
          v-print="'#result'"
          type="primary"
          icon="el-icon-receiving"
        >打印</el-button>-->
        <!-- <el-button type="text" style="float: right;color: #999;" @click="$router.go(-1)">X</el-button> -->
        <div class="title">{{detailData.name}}</div>
      </div>
      <!--  -->
      <div class="viewbox" id="result">
        <div class="baseWarp" :style="$route.name=='PowerResultDetail'?'padding:20px 145px':''">
          <div class="baseBox">
            <div>
              <!-- <label>规划名称：</label>第一季度人力规划 -->
            </div>
            <div>
              <label>一级部门：</label>
              {{detailData.orgPidName}}
            </div>
            <div>
              <label>部门人数：</label>
              {{departPeopleCount}}
            </div>
          </div>
          <div class="baseBox">
            <div>
              <label>起止时间：</label>
              <span v-show="detailData.planStartTime&&detailData.planEndTime">
                <i class="el-icon-date"></i>
                {{detailData.planStartTime}} 至 &nbsp;{{detailData.planEndTime}}
              </span>
            </div>
            <div>
              <label>二级部门：</label>
              {{detailData.orgIdName}}
            </div>
            <div>
              <!-- <label>岗位：</label>前端开发 -->
            </div>
          </div>
        </div>
        <div></div>

        <div class="information">
          <div class="title-icon">基本信息</div>
          <!-- <div class="resultTitle">java工程师岗位信息</div> -->
          <div class="result-w">
            <InfoPanel
              v-for="(item,i) in info"
              :key="i"
              :info="item"
              :educationOptions="educationOptions"
            />
          </div>
          <!-- <div class="resultWrap">
            <div class="baseWarp">
              <div class="baseBox">
                <div>
                  <label>需求岗位：</label>java工程师
                </div>
                <div>
                  <label>部门岗位人数：</label>20
                </div>
                <div>
                  <label>需求职级：</label>10
                </div>
                <div>
                  <label>需求专业：</label>计算机与科学
                </div>
                <div>
                  <label>其他需求：</label>其他
                </div>
              </div>
              <div class="baseBox">
                <div>
                  <label>岗位现有人力：</label>20
                </div>
                <div>
                  <label>需求数量：</label>12
                </div>
                <div>
                  <label>截止时间：</label>2019-12-20
                </div>
              </div>
            </div>
            <div class="skill">
              <label>技能需求：</label>
              <p>结果详情</p>
            </div>
            <div class="skill">
              <label>需求原因：</label>
              <p>其他</p>
            </div>
            <div class="skill">
              <label>岗位说明：</label>
              <p>洽谈</p>
            </div>
          </div>-->
        </div>

        <div></div>
        <!-- 附件说明 -->
        <div class="enclosure">
          <div class="title-icon">岗位说明书附件</div>
          <div class="content">
            <label>岗位说明书附件：</label>
            <div>
              <div class="book" v-for="(val,index) in enclosureList" :key="index">
                <!-- <i class="el-icon-document"></i> -->
                <doc-file
                  v-for="(item,i) in val"
                  :key="i"
                  :file-name="item.name + ' ('+ Math.ceil((Number(item.size)/(1024*1024))*100)/100+'M)'"
                  :url="item.url"
                >岗位说明书模板.doc(1.8M)</doc-file>
                <!-- 岗位说明书.doc(1.8M) -->
              </div>
            </div>
          </div>
        </div>
        <!-- 审批信息 -->
        <div class="approver">
          <div class="title-icon">审批信息</div>
          <div class="content result-w">
            <div class="cont_t">
              <div class="cont-title">
                <label>审批人：</label>
              </div>
              <div class="cont-content">
                <div class="go" v-for="(item) in detailData.approvalDetailList" :key="item.staffId">
                  <span
                    :class="[item.staffStatus=='3'?'active':'']"
                  >{{item.name}} {{ item.staffStatus=='3'? '(离职)':'' }}</span>
                  <div class="go_con">
                    <!-- <el-button type="success" size="mini">通过</el-button> -->
                    <el-tag
                      v-for="(v,i) in btnType"
                      :key="i"
                      :type="getbBtnType(item.approvalStatus)"
                    >{{item.approvalStatusName}}</el-tag>
                    <!-- item.approvalStatus=='0'?'info':'success' -->
                    <!-- style="font-size:14px; padding: 0 20px;color:#999;" -->
                    <span class="reason" v-if="item.remark">{{item.approvalStatusName}}原因：</span>
                    <p class="con-text" v-if="item.remark">
                      <span>{{item.remark}}</span>
                    </p>
                  </div>
                </div>
                <!-- <div class="out">
                  <span>李四</span>
                  <div class="go_con">
                    <el-button type="danger" size="mini">不通过</el-button>
                    <span style="font-size:14px; padding: 0 20px;color:#999;">不通过原因：</span>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InfoPanel from '../components/InfoPanel';
import { getMatchResultDetail, getPositionNum } from '@/api/manpowerplanning';
// import { parseTime } from "@/utils/index";
import DocFile from '@/components/DocFile';
import { findTreeElement } from '../const';
import { queryDepartment } from '@/api/workbench';
// getDictName,
import { getEducationOptions } from '@/utils/dict';
export default {
  props: {
  //   planId: {
  //     type: Number
  //   }
    iscompoent: {
      type: Boolean
    }
  },
  components: {
    InfoPanel,
    DocFile
  },

  data() {
    return {
      detailData: {},
      info: {},
      enclosureList: [],
      type: '1',
      btnType: [
        { type: 'primary', text: 'bohui' }
        // { type: "primary", text: "审批中" },
        // { type: "success", text: "审批通过" },
        // { type: "danger", text: "不通过" }
      ],
      departmentOptions: null,
      departPeopleCount: null, // 部门人数
      educationOptions: []
    };
  },

  created() {},
  mounted() {
    this.$nextTick(function() {
      this.getDetail();
      this.educationOptions = getEducationOptions();
    });
  },
  methods: {
    getbBtnType(type) {
      if (type == '0') {
        return 'primary';
      }
      if (type == '2') {
        return 'success';
      }
      if (type == '1') {
        return 'danger';
      }
    },
    getDetail() {
      const params = {
        planId: this.$route.query.planId
      };

      getMatchResultDetail(params).then(async (res) => {
        // console.log(res);
        if (res) {
          // res.planEndTime =
          //   res.planEndTime && parseTime(res.planEndTime, "{y}-{m}-{d}");
          // res.planStartTime =
          //   res.planStartTime && parseTime(res.planStartTime, "{y}-{m}-{d}");

          this.detailData = res;
          this.enclosureList = res.accessoryInfos && res.accessoryInfos;
          if (res.orgId && res.basicRequestList) {
            let num;

            res.basicRequestList.forEach(async (v, i) => {
              num = await getPositionNum({
                jobId: v.jobId,
                orgId: res.orgId
              });
              v.depCount = num.depCount;
              v.sumCount = num.sumCount;
              this.info =
                res.basicRequestList[i] &&
                res.basicRequestList[res.basicRequestList.length - 1]
                  .sumCount &&
                res.basicRequestList;
            });
            // console.log(this.info);
          } else {
            // let num;
            res.basicRequestList.forEach(async (v, i) => {
              this.info =
                res.basicRequestList[i] &&
                res.basicRequestList[res.basicRequestList.length - 1] &&
                res.basicRequestList;
            });
          }
        }

        queryDepartment({ request: {} }).then((data) => {
          this.departmentOptions = data;
          // console.log(this.departmentOptions, res.orgId);
          this.getDepartment(`${res.orgId}`, this.departmentOptions);
        });
      });
    },
    getSubDepartmentTreeData(parentId, data) {
      const res = findTreeElement(parentId, data);

      return res;
    },

    getDepartment(id) {
      const res = this.getSubDepartmentTreeData(id, this.departmentOptions);
      // console.log(res);

      if (res) {
        this.departPeopleCount = res.countNumber;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.result-w {
  width: calc(100% - 160px);
  margin: 0 auto;
}
.active {
  color: #f00;
}
.link-text {
  // white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    color: #66b1ff;
  }
}
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.el-button {
  cursor: auto;
}
.inputDisable {
  background: #f5f7fa;
  color: #c0c4cc;
  cursor: not-allowed;
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin-bottom: 20px;
    }
    .lastone {
      margin: 0px 0 24px;
    }
    .viewbox {
      margin: 0 20px;
      min-height: 300px;
    }
  }
}
.baseWarp {
  display: flex;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  .baseBox {
    width: calc(100% / 2);
    // height: 100px;
    line-height: 40px;
    font-size: 14px;
    label {
      font-size: 16px;
      color: #777e8c;
      font-weight: normal;
    }
  }
}
.resultWrap {
  padding: 10px 30px;
}

.information {
  // height: 500px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  label {
    width: 120px;
    text-align: right;
    font-weight: normal;
    display: inline-block;
  }
  .resultTitle {
    padding: 20px 0 0 30px;
    font-size: 22px;
    font-weight: 600;
  }
  .baseWarp {
    display: flex;
  }
  .skill {
    padding: 10px 0;
    display: flex;
  }
}
.enclosure {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  .content {
    display: flex;
    padding: 20px 0px;
    width: calc(100% - 134px);
    margin: 0 auto;
    label {
      width: 140px;
      font-size: 16px;
      font-weight: normal;
      color: #777e8c;
    }
    .book {
      padding-bottom: 20px;
      font-size: 14px;
    }
  }
}

.approver {
  padding: 20px 20px 0;
  background-color: #fff;
  margin-bottom: 20px;
  .content {
    padding: 20px 0px;
    .cont_t {
      // padding-bottom: 20px;
      vertical-align: middle;
      overflow: hidden;
      // display: flex;
      .cont-title {
        width: 112px;
        float: left;
        // display: flex;
        // align-items: center;
        // text-align: right;
        label {
          display: flex;
          height: 28px;
          align-items: center;
          font-weight: normal;
          justify-content: flex-end;
          font-size: 16px;
          color: #777e8c;
          vertical-align: middle;
        }
      }
      > .cont-content {
        vertical-align: middle;
        float: left;
        min-width: 48%;
        // padding: 0 30px;
        .go,
        .out {
          // display: flex;
          font-size: 14px;
          align-content: center;
          overflow: hidden;
          vertical-align: middle;
          padding-bottom: 16px;
          > span {
            // padding: 0 40px 0 10px;
            // height: 21px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // display: inline-block;
            float: left;
            min-width: 13%;
            margin-right: 10px;
            line-height: 28px;
            // width: 130px;
            text-align: center;
          }
          .go_con {
            // display: flex;
            .el-tag {
              float: left;
            }
            .reason{
              float: left;
              line-height: 28px;
              margin-left: 15px;
            }
            vertical-align: middle;
            .con-text {
              // vertical-align: middle;
              float: left;
              max-width: 58.94%;
              box-sizing: border-box;
              line-height: 28px;
              // display: flex;
              // align-items: center;
            }
          }
        }
      }
    }
  }
}

.title-icon {
  font-size: 18px;
  display: flex;
  align-items: center;
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    background: #498df0;
    content: " ";
    transform: translateY(1px);
    border-radius: 5px;
    vertical-align: middle;
    margin-right: 10px;
  }
}
// .left_label {
//   margin-right: 30px;
//   color: #999999;
//   text-align: right;
//   width: 130px;
//   vertical-align: top;
//   display: inline-block;
// }
// .right_label {
//   width: 50%;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   text-align: left;
//   font-weight: normal;
// }
// .rowResult div {
//   display: inline-block;
//   width: calc(100% / 2);
//   margin-top: 20px;
// }
// .infoBox {
//   padding: 20px 40px;
// }
// .indicator {
//   margin-bottom: 40px;
// }
// .infoTitle {
//   font-weight: bold;
//   margin-bottom: 20px;
// }
// .options {
//   margin: 10px;
//   line-height: 1.7em;
// }
// .header {
//   background: red;
// }
</style>

